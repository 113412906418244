import { Button } from 'nhsuk-react-components';
import React from 'react';

interface ButtonPropsI {
  text: string;
  onClick?: CallableFunction;
  className?: string;
  disabled?: boolean;
}

const ButtonComponent = (props: ButtonPropsI): JSX.Element => {
  const { text, onClick, className, disabled } = props;
  return (
    <Button
      type="button"
      className={className}
      onClick={(event: any): void => {
        event.target.blur();
        return onClick ? onClick(): null;
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
