import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

interface BlockPropsI {
  ip: string;
  countryName: string;
}

const BlockComponent = (props: BlockPropsI): JSX.Element => {
  const { ip, countryName } = props;

  return (
    <div className="bg-white w-full lg:w-9/12 mx-auto nhsuk-u-padding-4 rounded shadow-xl lg:max-w-5xl">
      <Label size="m">{translate('HOME.ACCESS_AVAILABLE_ENGLAND')}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate('HOME.YOUR_IP')}
        {ip}
        {translate('HOME.IS_LOCATED_IN')}
        {countryName}
      </p>
      <p className="nhsuk-body">
        {translate('HOME.YOURE_TRYING_PROGRAME')}
        {translate('HOME.IF_YOU_NEED_MORE_HELP')}
        <a href="mailto:healthyliving@support.changinghealth.com">healthyliving@support.changinghealth.com</a>
        .
      </p>
    </div>
  );
};

export default BlockComponent;
