import { translate } from 'app/core';
import { Details, Fieldset, Radios } from 'nhsuk-react-components';
import React from 'react';

interface SelectPropsI {
  id: string;
  label: string;
  options: string[];
  error: string | boolean | undefined;
  onChange: CallableFunction;
  hintText?: string;
  detailsText?: string;
  disabled?: boolean;
}

const MultiRadioComponent = (props: SelectPropsI): JSX.Element => {
  const { id, label, options, error, onChange, hintText, detailsText, disabled } = props;

  return (
    <Fieldset>
      <Fieldset.Legend className="nhsuk-label--s">{label}</Fieldset.Legend>
      { detailsText ?
        (
          <Details>
            <Details.Summary className="details-summary">{translate('HOME.FORM.WHY_ASKING_THIS_INFO')}</Details.Summary>
            <Details.Text>
              {detailsText}
            </Details.Text>
          </Details>
        )
        : null}
      <Radios
        name={id}
        id={id}
        hint={hintText}
        error={error}
      >
        {options.map((option) => <Radios.Radio key={option} value={option} onChange={(): void => onChange(option)} disabled={disabled}>{option}</Radios.Radio> )}
      </Radios>
    </Fieldset>
  );
};

export default MultiRadioComponent;
