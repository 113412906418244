import { BehaviorSubject } from 'rxjs';

import configuration from 'config';
import { User, UserDTO } from 'app/shared/models';
import { LoggingService, apiServiceInstance } from 'app/core/services';
import { AxiosResponse } from 'axios';

export class AuthService {
  private readonly USER_KEY = 'userKey';
  private apiUrl = configuration.apiUrl;
  private userSubject = new BehaviorSubject<User | null>(null);
  readonly user$ = this.userSubject.asObservable();
  readonly http = apiServiceInstance;

  constructor() {
    this.init();
  }

  private init(): void {
    this.setUser(this.getStoredUser());
  }

  private getStoredUser(): User | null {
    let user = null;
    const data = localStorage.getItem(this.USER_KEY);

    if (data) {
      try {
        user = new User().deserialize(JSON.parse(data));
      } catch (error) {
        user = null;
        LoggingService.error({ error });
      }
    }

    return user;
  }

  get user(): User | null {
    return this.userSubject.getValue();
  }

  private setUser(user: User | null): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));

    this.userSubject.next(user);

    this.http.setToken(user ? user.token : null);
  }

  isLoggedIn(): boolean {
    return this.user !== null;
  }

  async checkIfClient(nif: string, cups: string): Promise<boolean> {
    const response = await this.http.get(`${this.apiUrl}/auth/es-cliente/${cups}/${nif}/`);

    return response.data === 'si';
  }

  async signIn(nif: string, password: string): Promise<User | null> {
    let user: User | null = null;

    const response: AxiosResponse<{user: UserDTO; token: string}> = await this.http.post(
      `${this.apiUrl}/auth/sign-in/`,
      { username: nif, password },
    );
    const {user: userApi, token} = response.data;

    if (token) {
      user = new User().deserialize(userApi);

      if (user) {
        user.token = token;
      }

      this.setUser(user);
    }

    return user;
  }

  changePassword(password: string, nuevaPassword: string): Promise<unknown> {
    return this.http.post(
      `${this.apiUrl}/auth/cambiar-password/`,
      { password, nuevaPassword },
    );
  }

  recoverPassword(password: string, token: string): Promise<AxiosResponse> {
    return this.http.post(
      `${this.apiUrl}/auth/recuperar-password/`,
      { token, password },
    );
  }

  logOut(): void {
    this.setUser(null);
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
