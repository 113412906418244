import { Serializable } from 'app/core/interfaces';

export interface UserDTO {
  id: string;
  email: string;
  name: string;
}

export interface UserI {
  id: string;
  token: string;
  email: string;
  name: string;
}

export default class User implements Serializable<User> {
  id: string;
  email: string;
  name: string;
  token: string;

  constructor(userData?: User) {
    if (!userData) {
      return;
    }

    this.id = userData.id;
    this.email = userData.email;
    this.name = userData.name;
    this.token = userData.token;
  }

  deserialize(input: UserDTO): User | null {
    if (!input) {
      return null;
    }

    const user = new User();

    user.id = input.id || '';
    user.email = input.email || '';
    user.name = input.name || '';

    return user;
  }

  deserializeArray(inputArray: Array<UserDTO>): Array<User | null> {
    const users: Array<User | null> = inputArray.map(
      (input) => new User().deserialize(input),
    );

    return users;
  }

  clone(): User {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }
}
