import { translate } from 'app/core';
import React from 'react';

import './P404.component.scss';

interface P404Props{
  text?: string;
}

const P404Component = (props: P404Props): JSX.Element => {
  const { text } = props;

  return (
    <div className="nhsuk-u-margin-bottom-7">
      <h2 className="nhsuk-heading-l nhsuk-u-margin-top-4">{translate('404.ERROR_CODE_404')}</h2>
      <h4 className="nhsuk-heading-s nhsuk-u-margin-top-4">
        {translate('404.NO_MATCH_FOR')}
        {' '}
        { text }
        {' '}
        <code>{window.location.pathname}</code>
      </h4>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('404.PLEASE_TRY_AGAIN')}</p>
    </div>
  );
};

export default P404Component;
