import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Navigator } from './navigation';
import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => (
  <>
    <ToastContainer />
    <Navigator />
  </>
);

export default App;
