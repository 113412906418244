import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

const AgeViewComponent = (): JSX.Element => {

  return (
    <div className="nhsuk-u-margin-bottom-4 nhsuk-u-margin-top-4">
      <div className="bg-white w-full lg:w-9/12 mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
        <div className="mr-0 lg:mr-4 mx-auto">
          <Label size="m">{translate('AGE.HL_PROGAMME_AVAILABLE_TO')}</Label>
          <section>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3">
              {translate('AGE.EMAIL_US_FOR_INFORMATION')}
              <a href="mailto:healthyliving@support.changinghealth.com">{translate('AGE.EMAIL_US_FOR_INFORMATION_LINK')}</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AgeViewComponent;
