import React, { useEffect, useState } from 'react';

import './Home.component.scss';
import { translate } from 'app/core';
import ButtonComponent from 'app/shared/components/Button/Button';
import { Row, Col } from 'nhsuk-react-components';
import ipApiServiceInstance, { IpApiI } from 'app/shared/services/IpApiService';
import BlockComponent from './Block/Block.component';
import InfoComponent from './Info/Info.component';
import FormComponent from './Form/Form.component';

const HomeComponent = (): JSX.Element => {
  const [isCountryAvailable, setIsCountryAvailable] = useState<IpApiI>();

  const getCountryAvailable = async (): Promise<void> => {
    const ip = await ipApiServiceInstance.getIP();
    if (ip) {
      setIsCountryAvailable(ip as IpApiI);
    }
  };

  useEffect(() => {
    getCountryAvailable();
  }, []);

  return (
    <div className="nhsuk-u-margin-bottom-4">
      {
        isCountryAvailable && !isCountryAvailable.isValid
          ? <BlockComponent ip={isCountryAvailable.ip} countryName={isCountryAvailable.countryName} />
          : (
            <div className="bg-white w-full mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
              <Row>
                <Col width="one-half">
                  <FormComponent />
                </Col>
                <Col width="one-half">
                  <InfoComponent />
                </Col>
              </Row>
              <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">
                <h2 className="nhsuk-label--m">
                  {translate('HOME.ALREADY_REGISTERED')}
                </h2>

                <section>
                  <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                    {translate('HOME.LOGIN_TO_HL')}
                  </p>
                  <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                    {translate('HOME.YOU_WILL_NEED')}
                  </p>
                </section>

                <div className="nhsuk-u-margin-top-6 text-center lg:text-left">
                  <a href="https://app.changinghealth.com/">
                    <ButtonComponent className="nhsuk-u-margin-bottom-0" text={translate('HOME.LOGIN')} />
                  </a>
                </div>
              </div>
            </div>
          )
      }
    </div>
  );
};

export default HomeComponent;
