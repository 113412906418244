import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const navigate = async (url: string): Promise<void> => history.push(url);

const goBack = async (): Promise<void> => history.goBack();

export {
  history,
  navigate,
  goBack,
};
