import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import P404Component from '../Error/P404/P404.component';

import './PoliciesView.component.scss';

interface RouteParams {
  slug: string;
}

type PoliciesViewComponent = RouteComponentProps<RouteParams>

const PoliciesViewComponent: React.FC<PoliciesViewComponent> = ({match}) => {
  const {slug} = match.params;
  let isPageFounded = false;
  const html = (): string => {
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
      const htmlDefault = require(`assets/policies-templates/${slug}.html`).default;
      isPageFounded = true;
      return htmlDefault;
    } catch(err) {
      return '';
    }
  };
  const template = { __html: html() };

  return (
    <div className="policies">
      {
        isPageFounded
          ? <div dangerouslySetInnerHTML={template} />
          : <P404Component />
      }
    </div>
  );
};

export default PoliciesViewComponent;
