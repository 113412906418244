const translations = {
  EMAIL_HL: 'healthyliving@support.changinghealth.com',
  OPENS_IN_NEW_TAB: 'Opens in new tab',
  OPENS_IN_EMAIL_APP: 'Opens in email application',
  FORM: {
    PLEASE_SELECT: 'Please Select',
    PLEASE_CORRECT_FORM_ERRORS: 'Please correct the form errors'
  },
  HOME: {
    CLICK: 'Click Me',
    LOGIN: 'Login',
    ALREADY_REGISTERED: 'Already registered?',
    LOGIN_TO_HL: 'Login to Healthy Living by clicking the button below. The link will take you to the login page of our programme partner Changing Health.',
    YOU_WILL_NEED: 'You will need your username and password to access the programme.',
    INFO: {
      WHAT_IS: 'What is Healthy Living for people with type 2 diabetes?',
      HEALTHY_LIVING_IS: 'Healthy Living is a free online NHS service for people living with type 2 diabetes. It provides knowledge and information that will support you to manage your condition.',
      HEALTHY_LIVING_IT_INCLUDES: 'It includes:',
      HEALTHY_LIVING_IS_STEP_1: 'information about type 2 diabetes and its treatments;',
      HEALTHY_LIVING_IS_STEP_2: 'advice on emotional and mental wellbeing;',
      HEALTHY_LIVING_IS_STEP_3: 'advice with adopting and maintaining healthy behaviours with food and exercise.',
      GET_HELP: 'Get help',
      IF_YOU_NEED_HELP: 'If you need help with joining or using the Healthy Living programme, ',
      EMAIL_US: 'email us',
      FOR_FURTHER_SUPPORT: ' for further support.',
      IF_YOU_NEED_HELP_CALL: 'If you would prefer to speak to one of our team, call 0191 249 7801.',
      SUPPORT_TEAM_AVAILABLE: 'Our support team are available Monday to Friday, 9am to 5pm.',
      CANNOT_OFFER_MEDICAL_SUPPORT_TELEPHONE: 'We cannot offer medical advice. The support telephone contact and email address are not monitored by medically trained members of staff.',
      CONCERNED_ABOUT_YOUR_HEALTH: 'If you are concerned about your health, please contact a health professional such as your GP.',
      NEED_MEDICAL_HELP_NOW_1: 'If you need medical help now, but it is not an emergency go to ',
      NEED_MEDICAL_HELP_NOW_LINK: '111.nhs.uk',
      NEED_MEDICAL_HELP_NOW_2: ' or call 111 from any landline or mobile phone free of charge.',
      DEAF_PEOPLE_CAN_USE_TEXT: 'Deaf people can use text relay to call 18001 111.',
      RESOURCES_TYPE_2_DIABETES_TITLE: 'Resources for people who are at risk of developing diabetes',
      RESOURCES_TYPE_2_DIABETES_1: 'People who are at risk of developing type 2 diabetes can access a different NHS service called Healthier You: NHS Diabetes Prevention Programme.',
      RESOURCES_TYPE_2_DIABETES_2: 'When blood sugar levels are above the normal range, but not high enough to be diagnosed as having diabetes, this is called non-diabetic hyperglycaemia (NDH) or sometimes known as pre-diabetes.',
      RESOURCES_TYPE_2_DIABETES_3: 'Your GP practice can refer you to the Healthier You: NHS Diabetes Prevention Programme if you have had a blood test in the last 12 months confirming pre-diabetes.',
      RESOURCES_TYPE_2_DIABETES_4: 'If you do not have a diagnosis of type 2 diabetes or pre-diabetes, complete this short questionnaire to find out your type 2 risk score. It only takes a few minutes. It could be the most important thing you do today.',
      RESOURCES_TYPE_2_DIABETES_LINK: 'Find out your type 2 risk score',
      RESOURCES_TYPE_1_DIABETES_TITLE: 'Resources for people living with type 1 diabetes',
      RESOURCES_TYPE_1_DIABETES_1: 'People who are living with type 1 diabetes can access different NHS services available through the NHS website.',
      RESOURCES_TYPE_1_DIABETES_2: 'People living with type 1 diabetes need to manage their condition differently to those living with type 2 diabetes.',
      RESOURCES_TYPE_1_DIABETES_3: 'Click on the link below to view the NHS type 1 diabetes page which includes information, support and access to services for people living with type 1 diabetes.',
      RESOURCES_TYPE_1_DIABETES_LINK: 'Visit the NHS type 1 diabetes page',
      RESOURCES_HEALTHCARE_PROFESSIONALS: 'Resources for healthcare professionals',
      HEALTHCARE_PROFESSIONAL_INFO_1: 'If you’re a healthcare professional and are interested in referring into the programme, contact NHS England at ',
      HEALTHCARE_PROFESSIONAL_INFO_2: ' for implementation advice and resources.',
      HEALTHCARE_PROFESSIONAL_INFO_EMAIL: 'england.digitaldiabetes@nhs.net'
    },
    FORM: {
      REGISTER_FOR_THE_PROGRAMME: 'Register for the programme',
      KNOWDIABETES_SUBTITLE: 'Know Diabetes and Healthy Living are working in collaboration to help people across North West London live well with their type 2 diabetes. If you live anywhere else in England, you can sign up here: ',
      HL_EMAIL: 'https://www.healthyliving.nhs.uk/',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      EMAIL: 'Email',
      DATE_OF_BIRTH: 'Date of birth',
      DATE_OF_BIRTH_EXAMPLE: 'For example, 1 2 1990',
      GENDER: 'Gender',
      GENDER_POSTCODE_DESCRIPTION: 'We collect this data to monitor equity of access to the service.',
      POSTCODE: 'Postcode',
      WHY_ASKING_THIS_INFO: 'Why are we asking for this information ?',
      WHICH_DESCRIBES_YOU: 'Which of the following best describes you?',
      I_AGREE_TO: 'I agree to:',
      PRIVACY_POLICY: 'Privacy Policy',
      REGISTER: 'Register',
      BY_FILLING_FORM_YOU_ARE_CONSENTING :'* By filling this form you are consenting to share your personal information with NHS England. Your information will be held confidentially and will not be shared with any third parties. For more information review our ',
      CHANGING_HEALTH: 'Changing Health\'s ',
      TERMS_CONDITIONS: 'Terms & Conditions',
      HEALTHY_LIVING: 'Healthy Living ',
      SERVICE_DESCRIPTION: 'Service Description',
      FAIR_PROCESSING_NOTICE: 'Fair Processing Notice',
      CONSENT_GP: 'Can we tell your GP you have used Healthy Living?',
      CONSENT_GP_DESCRIPTION: 'This will help them keep your health record up to date.',
      YES: 'Yes',
      NO: 'No',
      ERROR: {
        CANT_BE_BLANK_MIN_2: "Can't be blank, is too short (minimum is 2 character)",
        TO_LONG_MAX_50: 'Is too long (maximum are 50 characters)',
        INVALID_CHARACTERS_FIRST_NAME: 'First name uses special characters that are not allowed.',
        INVALID_CHARACTERS_LAST_NAME: 'Last name uses special characters that are not allowed.',
        DATE_OF_BIRTH_REAL_DATE: 'Date of birth must be a real date.',
        EMAIL_ADDRESS_EMPTY: 'Enter an email address in the correct format, like name@example.com',
        POSTCODE_REAL: 'Check that you have typed your postcode correctly.',
        SELECT_ANSWER: 'You must select an answer',
        AGREE_PRIVACY_POLICY: 'You will need to agree to the privacy policy to continue with registration.',
        EMAIL_ADDRESS_IN_USE: 'The provided email address is already in use.',
        USE_A_DIFFERENT_ADDRESS: 'Please use a different email address, or go to ',
        WITH_EXISTING_ACCOUNT: ' to login with your existing email address or reset your password.',
        EMAIL_FORMAT: "The provided email address is not in the correct format.",
        GENERIC: "We weren't able to process your form",
        GENERIC_DESCRIPTION: 'Please try completing and sending the form again. If you are still having problems contact us at ',
        GENDER: 'Select your gender.',
        DESCRIBES_YOU: 'Select the answer that best describes you.',
        HOW_FIND: 'Select where you first heard about the Healthy Living programme.',
        CONSENT_GP: 'Select yes if you want us to tell your GP that you have used the Healthy Living programme.'
      },
    },
    ACCESS_AVAILABLE_ENGLAND: 'Access to this programme is only available in England.',
    YOUR_IP: 'Your IP: ',
    IS_LOCATED_IN: ' is located in ',
    YOURE_TRYING_PROGRAME: 'It looks like you’re trying to sign up to the Healthy Living programme from outside of England. ',
    IF_YOU_NEED_MORE_HELP: 'If you need more help please contact us at ',
  },
  HEADER: {
    LOGIN: 'Login',
    LOGO_HL: 'Healthy Living logo',
    LOGO_NHS: 'Healthy NHS logo',
    TITLE: 'Healthy Living for people with type 2 diabetes',
  },
  FOOTER: {
    ACCESSIBILITY_STATEMENT: 'Accessibility statement',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_AND_CONDITIONS: 'Terms and conditions',
    SERVICE_DESCRIPTION: 'Service Description',
    FAIR_PROCESSING_NOTICE: 'Fair Processing Notice',
  },
  REGISTERED: {
    CHECK_YOUR_EMAIL: 'Check your email inbox to accept your invitation to Healthy Living',
    CHECK_YOUR_EMAIL_1: 'Open the email and click the ‘Accept invitation’ link',
    CHECK_YOUR_EMAIL_2: 'Check your spam or junk folder if you cannot see the email',
    CHECK_YOUR_EMAIL_3: 'The email will be from our delivery partner, Changing Health, at ',
    NO_REPLY_EMAIL: 'no-reply@changinghealth.com.',
    IF_NOT_RECEIVED: 'If you have not received the email within 15 minutes',
    EMAIL_US_FOR_HELP: 'Email us for help at ',
    EMAIL_US_FOR_HELP_LINK: 'healthyliving@support.changinghealth.com',
  },
  AGE: {
    HL_PROGAMME_AVAILABLE_TO: 'The Healthy Living programme is available to people aged 18 years or over.',
    EMAIL_US_FOR_INFORMATION: 'Ask your GP for information about diabetes support available for people under 18 in your area, or email us at ',
    EMAIL_US_FOR_INFORMATION_LINK: 'healthyliving@support.changinghealth.com',
  },
  404: {
    NO_MATCH_FOR: 'No match for',
    ERROR_CODE_404: 'Error code 404',
    PLEASE_TRY_AGAIN: 'If you typed in the web address, please check it is correct and try again.'
  }
};

export default translations;
