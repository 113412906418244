import React from 'react';

import './BaseLayout.scss';

import { Footer, Header } from 'app/shared/components';
import { RoutesInterface } from 'app/core/interfaces';
import { Container } from 'nhsuk-react-components';

const BaseLayout = (props: RoutesInterface): JSX.Element | null => {
  const { component: Component } = props;

  return (
    <>
      <Header />
      <div className="BaseLayout">
        <main role="main" className="main">
          <Container>
            { Component ? <Component {...props} /> : null }
          </Container>
        </main>
      </div>
      <Footer />
    </>
  );
};
export default BaseLayout;
