import { translate } from 'app/core';
import { ActionLink } from 'nhsuk-react-components';
import React from 'react';

const InfoComponent = (): JSX.Element => {
  return (
    <div className="mr-0 lg:mr-4 mx-auto">
      <h2 className="nhsuk-u-margin-bottom-4 nhsuk-label--m nhsuk-u-margin-top-4">
        {translate('HOME.INFO.WHAT_IS')}
      </h2>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.INFO.HEALTHY_LIVING_IS')}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate('HOME.INFO.HEALTHY_LIVING_IT_INCLUDES')}
        </p>
        <ul className="nhsuk-list nhsuk-list--bullet">
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_1')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_2')}</li>
          <li>{translate('HOME.INFO.HEALTHY_LIVING_IS_STEP_3')}</li>
        </ul>
      </section>

      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.GET_HELP')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.IF_YOU_NEED_HELP')}
            <a href="mailto:healthyliving@support.changinghealth.com">
              <span>{translate('HOME.INFO.EMAIL_US')}</span>
              <span className="visually-hidden">{translate('OPENS_IN_EMAIL_APP')}</span>
            </a>
            {translate('HOME.INFO.FOR_FURTHER_SUPPORT')}
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.IF_YOU_NEED_HELP_CALL')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.SUPPORT_TEAM_AVAILABLE')}</p>
        </section>
      </div>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CANNOT_OFFER_MEDICAL_SUPPORT_TELEPHONE')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.CONCERNED_ABOUT_YOUR_HEALTH')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_1')}
            <a href="https://111.nhs.uk">{translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_LINK')}</a>
            {translate('HOME.INFO.NEED_MEDICAL_HELP_NOW_2')}
          </p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.DEAF_PEOPLE_CAN_USE_TEXT')}</p>
        </section>
      </div>

      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_TITLE')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_1')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_2')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_3')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_4')}</p>
        </section>
        <ActionLink href="https://riskscore.diabetes.org.uk/start" target="_blank" rel="noreferrer">
          <span>{translate('HOME.INFO.RESOURCES_TYPE_2_DIABETES_LINK')}</span>
          <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
        </ActionLink>
      </div>

      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_TITLE')}
      </h2>

      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_1')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_2')}</p>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_3')}</p>
        </section>
        <ActionLink href="https://www.nhs.uk/conditions/type-1-diabetes/">{translate('HOME.INFO.RESOURCES_TYPE_1_DIABETES_LINK')}</ActionLink>
      </div>

      <h2 className="nhsuk-label--m">
        {translate('HOME.INFO.RESOURCES_HEALTHCARE_PROFESSIONALS')}
      </h2>
      <div className="nhsuk-u-margin-bottom-4 lg:mb-0">
        <section>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.INFO.HEALTHCARE_PROFESSIONAL_INFO_1')}
            <a
              href={`mailto:${translate(`${'HOME.INFO.HEALTHCARE_PROFESSIONAL_INFO_EMAIL'}`)}`} 
              className='nhsuk-link'
            >
              {translate('HOME.INFO.HEALTHCARE_PROFESSIONAL_INFO_EMAIL')}
            </a>
            {translate('HOME.INFO.HEALTHCARE_PROFESSIONAL_INFO_2')}
          </p>
        </section>
      </div>
    </div>
  );
};

export default InfoComponent;
