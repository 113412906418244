export enum GendersOptions{
  Male = 'Male',
  Female = 'Female',
  Intersex = 'Intersex',
  PreferNotToSay = 'Prefer not to say'
};
export enum DescribesYouOptions {
  Type2 = 'I have type 2 diabetes',
  RiskType2 = 'I’m at risk of type 2 diabetes',
  SupportPersonType2 = 'I care for / support a person with type 2 diabetes',
  Type1 = 'I have type 1 diabetes',
  HealthcareProfessional = 'I am a healthcare professional / I work in the diabetes field',
  NoneOfTheAbove = 'None of the above',
};
export enum ConsentGPOptions {
  Yes = 'Yes',
  No = 'No',
}
export interface RequestI {
  sys_first_name: string;
  sys_last_name: string;
  unit_id: number; // 34
  package_id: number; // 11
  email: string;
  enabled: true; // true
  welcome_message: true; // true
  birthdate: string; // '1990-01-01'
  pops: RequestPopsI[];
}

export interface RequestPopsI {
  category: string; // 'capturing-bmi'
  key: string; // 'weight'
  value: number | string | boolean; // 120
}
export interface RegisterFormI {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: {day: string; month: string; year: string};
  gender: string;
  postcode: string;
  describesYou: string;
  agree: boolean;
  consentGP: string;
  showErrors: boolean;
  map: Map<string, string>;
}

export default class RegisterForm implements RegisterFormI {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: {day: string; month: string; year: string};
  gender: string;
  postcode: string;
  describesYou: string;
  agree: boolean;
  consentGP: string;
  showErrors: boolean;
  map: Map<string, string>;

  constructor(registerForm?: RegisterFormI) {
    if (!registerForm) {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.dateOfBirth = {day: '', month: '', year: ''};
      this.gender = '';
      this.postcode = '';
      this.describesYou = '';
      this.agree = false;
      this.consentGP = '';
      this.showErrors = false;
      this.map = new Map<string, string>();
      return;
    }
    this.firstName = registerForm.firstName || '';
    this.lastName = registerForm.lastName || '';
    this.email = registerForm.email || '';
    this.dateOfBirth = registerForm.dateOfBirth || {day: '', month: '', year: ''};
    this.gender = registerForm.gender || '';
    this.postcode = registerForm.postcode || '';
    this.describesYou = registerForm.describesYou || '';
    this.agree = registerForm.agree || false;
    this.consentGP = registerForm.consentGP || '';
    this.showErrors = registerForm.showErrors || false;
    this.map = registerForm.map || new Map<string, string>();
  }

  validateForm(): void {
    const map: Map<string, string> = new Map<string, string>();
    const firstNameError = this.validateFirstName();
    if (firstNameError) {
      map.set('firstName', firstNameError)
    }
    const lastNameError = this.validateLastName();
    if (lastNameError) {
      map.set('lastName', lastNameError)
    }
    const dateOfBirthError = this.validateDateOfBirth();
    if (dateOfBirthError) {
      map.set('dateOfBirth', dateOfBirthError)
      if(this.validateDayDateOfBirth()) {
        map.set('dayDateOfBirth', 'dayDateOfBirthError')
      }
      if(this.validateMonthDateOfBirth()) {
        map.set('monthDateOfBirth', 'monthDateOfBirthError')
      }
      if(this.validateYearDateOfBirth()) {
        map.set('yearDateOfBirth', 'yearDateOfBirthError')
      }
    }
    const emailError = this.validateEmail();
    if (emailError) {
      map.set('email', emailError)
    }
    const postcodeError = this.validatePostCode();
    if (postcodeError) {
      map.set('postcode', postcodeError)
    }
    const genderError = this.validateGender();
    if (genderError) {
      map.set('gender', genderError)
    }
    const describesYouError = this.validateDescribesYou();
    if (describesYouError) {
      map.set('describesYou', describesYouError)
    }
    const agreeError = this.validateAgreePolicy();
    if (agreeError) {
      map.set('agree', agreeError)
    }
    const consentGPError = this.validateConsentGP();
    if (consentGPError) {
      map.set('consentGP', consentGPError)
    }
    this.map = map;
  }

  addError(key: string, textError: string): void {
    const map: Map<string, string> = new Map<string, string>(this.map);
    map.set(key, textError);
    this.map = map
  }

  private validateFirstName(): string {
    return this.validateFirstLastName(this.firstName);
  }

  private validateLastName(): string {
    return this.validateFirstLastName(this.lastName);
  }

  private validateFirstLastName(name: string): string {
    if (!name || name.length < 2) {
      return 'HOME.FORM.ERROR.CANT_BE_BLANK_MIN_2';
    }

    if (name.length > 50) {
      return 'HOME.FORM.ERROR.TO_LONG_MAX_50';
    }

    return '';
  }

  private validateDateOfBirth(): string {
    if (!this.dateOfBirth || this.validateDayDateOfBirth() || this.validateMonthDateOfBirth() || this.validateYearDateOfBirth()) {
      return 'HOME.FORM.ERROR.DATE_OF_BIRTH_REAL_DATE';
    }
    if (this.dateOfBirth) {
      const date = new Date(parseInt(this.dateOfBirth.year, 10), parseInt(this.dateOfBirth.month, 10) - 1, parseInt(this.dateOfBirth.day, 10)).getTime();
      const dateTo18Years = new Date().setFullYear(new Date().getFullYear() - 18);
      if (date > dateTo18Years) {
        return 'HOME.FORM.ERROR.UNDER_18_YEARS_OLD';
      }
    }
    return '';
  }

  private validateDayDateOfBirth(): boolean {
    if (!this.dateOfBirth || !this.dateOfBirth.day) {
      return true;
    }

    const day = parseInt(this.dateOfBirth.day, 10);
    if (day < 1 || day > 31) {
      return true;
    }
    return false;
  }

  private validateMonthDateOfBirth(): boolean {
    if (!this.dateOfBirth || !this.dateOfBirth.month) {
      return true;
    }

    const month = parseInt(this.dateOfBirth.month, 10);
    if (month < 1 || month > 12) {
      return true;
    }
    return false;
  }

  private validateYearDateOfBirth(): boolean {
    if (!this.dateOfBirth || !this.dateOfBirth.year) {
      return true;
    }

    const year = parseInt(this.dateOfBirth.year, 10);
    if (year < 1900 || year > new Date().getFullYear()) {
      return true;
    }
    return false;
  }

  private validateEmail(): string {
    if (!this.email) {
      return 'HOME.FORM.ERROR.EMAIL_ADDRESS_EMPTY';
    }
    const formatEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$$/i;

    if (!new RegExp(formatEmailRegex).test(this.email)) {
      return 'ERROR.EMAIL_ADDRESS_VALID';
    }
    return '';
  }

  private validatePostCode(): string {
    const postCodeRegex = /^([A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW])\s?[0-9][ABD-HJLNP-UW-Z]{2}|(GIR 0AA)|(SAN TA1)|(BFPO (C\/O )?[0-9]{1,4})|((ASCN|BBND|[BFS]IQQ|PCRN|STHL|TDCU|TKCA) 1ZZ))$$/i;
    if (!this.postcode || !new RegExp(postCodeRegex).test(this.postcode)) {
      return 'HOME.FORM.ERROR.POSTCODE_REAL'
    }
    return '';
  }

  private validateGender(): string {
    return this.isMultiRadioEmpty(this.gender) ? 'HOME.FORM.ERROR.GENDER' : '';
  }

  private validateDescribesYou(): string {
    return this.isMultiRadioEmpty(this.describesYou) ? 'HOME.FORM.ERROR.DESCRIBES_YOU' : '';
  }

  private validateConsentGP(): string {
    return this.isMultiRadioEmpty(this.consentGP) ? 'HOME.FORM.ERROR.CONSENT_GP' : '';
  }

  private isMultiRadioEmpty(select: string): boolean {
    if (!select) {
      return true;
    }
    return false;
  }

  private validateAgreePolicy(): string {
    if (!this.agree) {
      return 'HOME.FORM.ERROR.AGREE_PRIVACY_POLICY';
    }
    return '';
  }
}
