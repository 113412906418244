/* eslint-disable @typescript-eslint/camelcase */
import { apiServiceInstance } from 'app/core/services';
import { AxiosResponse } from 'axios';
import RegisterForm from '../models/registerForm';

export class IubendaService {
  private url = 'https://consent.iubenda.com/public';
  readonly http = apiServiceInstance;

  async createConsentIubenda(id: string, user: RegisterForm): Promise<AxiosResponse<unknown>> {
    const request = {
      subject : {
        id,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        verified: false,
      },
      legal_notices: [
        { identifier: 'privacy_policy' },
        { identifier: 'term' },
        { identifier: 'terms_nhs' },
        { identifier: 'privacy_nhs' },
      ],
    }
    return this.http.post(
      `${this.url}/consent`,
      { ...request },
      { headers: { ApiKey: 'DcFUq13fgyV2CVybC5DUUE4BNfTBgJvP' }}
    );
  }
}

const iubendaServiceInstance = new IubendaService();

export default iubendaServiceInstance;
