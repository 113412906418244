import React, { FormEvent } from 'react';
import { Details } from 'nhsuk-react-components';
import { translate } from 'app/core';


interface InputPropsI {
  id: string;
  label: string;
  value?: string;
  error: string | boolean | JSX.Element | undefined;
  onChange: CallableFunction;
  detailsText?: string;
  disabled?: boolean;
}

const InputComponent = (props: InputPropsI): JSX.Element => {
  const { id, value, label, onChange, error, detailsText, disabled } = props;

  return (
    <div className={
      `nhsuk-form-group ${error ? 'nhsuk-form-group--error': '' }`
    }
    >
      <label className="nhsuk-label nhsuk-label--s" htmlFor={id}>{label}</label>
      { detailsText ?
        (
          <Details>
            <Details.Summary className="details-summary">{translate('HOME.FORM.WHY_ASKING_THIS_INFO')}</Details.Summary>
            <Details.Text>
              {detailsText}
            </Details.Text>
          </Details>
        )
      : null }
      {
        error ?
          (
            <span className="nhsuk-error-message" id="example-error">
              <span className="nhsuk-u-visually-hidden">Error:</span>
              { error }
            </span>
          )
        : null
      }
      <input
        className={
          `nhsuk-input ${error ? 'nhsuk-input--error': '' }`
        }
        id={id}
        name={id}
        value={value}
        onChange={(e: FormEvent<HTMLInputElement>): void => onChange((e.target as HTMLInputElement).value)}
        disabled={disabled}
      />
    </div>
  );
};

export default InputComponent;
