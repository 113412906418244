import { apiServiceInstance } from "app/core/services";

export interface IpApiI {
  isValid: boolean;
  ip: string;
  countryName: string;
}

const COUNTRIES_AVAILABLES = ['GB'];
const WHITELISTED_IP: Array<string> = ['94.9.249.3', '5.102.3.76', '79.68.0.166', '109.144.24.253', '94.12.147.172', '90.250.0.179', '92.40.202.75', '85.255.232.86', '155.190.33.31', '155.190.33.28', '51.179.0.174'];
const GEO_IP_API_SERVICE_ENDPOINT = "https://get.geojs.io/v1/ip/geo.json";

export class IpApiService {
  readonly http = apiServiceInstance;

  async getIP(): Promise<IpApiI> {
    const response: {data: { ip: string; country: string; country_code: string; region: string}} = await this.http.get(GEO_IP_API_SERVICE_ENDPOINT);
    const { data } = response;
    let isValid = COUNTRIES_AVAILABLES.includes(data.country_code);
    isValid = (isValid || WHITELISTED_IP.includes(data.ip));
    return { isValid, ip: data.ip, countryName: data.country};
  }
}

const ipApiServiceInstance = new IpApiService();

export default ipApiServiceInstance;
