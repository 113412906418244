import { translate } from 'app/core';
import React from 'react';
import { Footer as FooterNhs } from 'nhsuk-react-components';

import './Footer.component.scss';
import { Link } from 'react-router-dom';

function Footer(): JSX.Element {
  return (
    <FooterNhs>
      <FooterNhs.List>
        <FooterNhs.ListItem href="https://www.changinghealth.com/policies/privacy-policy/" target="_blank">
          <span>{translate('FOOTER.PRIVACY_POLICY')}</span>
          <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
        </FooterNhs.ListItem>
        <FooterNhs.ListItem href="https://www.changinghealth.com/policies/terms-and-conditions/" target="_blank">
          <span>{translate('FOOTER.TERMS_AND_CONDITIONS')}</span>
          <span className="visually-hidden">{translate('OPENS_IN_NEW_TAB')}</span>
        </FooterNhs.ListItem>
        <li className="nhsuk-footer__list-item">
          <Link className="nhsuk-footer__list-item-link" to="/policies/healthy-living-fair-processing-notice" target="_blank">{translate('FOOTER.FAIR_PROCESSING_NOTICE')}</Link>
        </li>
        <li className="nhsuk-footer__list-item">
          <Link className="nhsuk-footer__list-item-link" to="/policies/service-description-healthy-living" target="_blank">{translate('FOOTER.SERVICE_DESCRIPTION')}</Link>
        </li>
        <li className="nhsuk-footer__list-item">
          <Link className="nhsuk-footer__list-item-link" to="/policies/accessibility-statement" target="_blank">{translate('FOOTER.ACCESSIBILITY_STATEMENT')}</Link>
        </li>
      </FooterNhs.List>
    </FooterNhs>
  );
}

export default Footer;
